<template>
  <div class="zxfwxq">
    <div class="header">
      <div class="h1">黄骅市综合服务平台</div>
      <div class="h2">全面・便捷・专业 助力企业成长</div>
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item  :to="{ path: '/zxfw' }">咨询服务</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/zxfwxq' }">查看详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="bottom">
        <div class="flex">
          <div class="flex-1">
            <div class="title">{{datas.name}}</div>
            <div>
              <p>{{datas.subTitle}}</p>
            </div>
          </div>
          <div class="img"><el-image :src="datas.image"></el-image></div>
        </div>
         <div class="t1"><span>机构介绍</span></div>
          <div v-html="datas.notes"></div>
        <div style="text-align: center;margin-top: 50px">
          <el-button type="danger" class="btn" @click.stop="gotolink()">在线咨询</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {intermediarydetail} from "../../request/moudle/zxfw";

export default {
  name: "gonggaoxq",
  data(){
    return {
      datas:''
    }
  },
  created() {
    this.loadxq()
  },
  methods:{
    loadxq(){
      this.$api.zxfw.intermediarydetail({
        id:this.$route.query.id
      }).then((res)=>{
        this.datas=res.data
      })
    },
    go(){
      this.$router.push({
        path:'/zxfwlist'
      })
    },
    gotolink(){
      if(this.datas.link){
        window.open(this.datas.link,'_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .zxfwxq{
     background: #fff;
    .header{
      height: 180px;
      background: url("../../assets/gxjy/gxjy.png") no-repeat;
      background-size: 100% 100%;
      padding-top: 90px;
      text-align: center;
      color: #fff;
      .h1{
        font-size: 42px;
        font-weight: 500;
      }
      .h2{
        font-size: 22px;
        line-height: 80px;
      }
    }
    .content{
      width: 1200px;
      margin: 20px auto;
      .bottom{
        padding: 30px;
      }
      .title{
        font-size: 18px;
        font-weight: 550;
        line-height: 50px;
      }
      .img{
        width: 350px;
        height: 200px;
        background: #F1F3F3;
        margin-left: 30px;
      }
      .t1{
        color: #fff;
        border-bottom: 1px solid #eeeeee;
        margin: 25px 0;
        padding-bottom: 11px;
        span{
          padding: 8px 15px;
          background: #0078F2 ;
          border-bottom: 2px solid  #0078F2;
        }
      }
    }
  }
</style>